<template>
  <div :class="$style.container">
    <div :class="$style.title">Latest News</div>
    <div :class="$style.marquee">
      <marquee scrollamount="10">
        <div :class="$style.content">
          <div
            v-for="(news, index) in newsList"
            :class="$style.line"
            :key="`news-${index}`"
          >
            {{ news }}
          </div>
        </div>
      </marquee>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ScrollingText',
  props: ['newsList']
}
</script>

<style lang="scss" module>
.container {
  display: flex;
  align-items: center;
  height: 100%;
  width: 100%;
}

.title {
  background: #4b92ec;
  color: white;
  font-weight: bold;
  font-size: 24px;
  white-space: nowrap;
  border-radius: 8px;
  flex: 1;
  margin: 0 20px;
  padding: 10px 20px;
}

.marquee {
  overflow: hidden;
  position: relative;
}

.content {
  display: flex;
  gap: 100px;
}

.line {
  white-space: nowrap;
  color: white;
  font-size: 22px;
  letter-spacing: 4px;
}
</style>
