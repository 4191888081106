<template>
  <div id="app">
    <div :class="$style.container" v-if="loaded">
      <div :class="$style.main">
        <div :class="$style.content">
          <div :class="$style.header">
            <weather :data="config.weather_widget" />
          </div>
          <div :class="$style.body">
            <live-video :video-id="config.youtube_video_id" />
          </div>
        </div>
        <div :class="$style.sidebar">
          <sidebar
            :slides="config.sidebar_slides"
            :duration="config.sidebar_slide_duration"
          />
        </div>
      </div>
      <div :class="$style.footer">
        <scrolling-text :news-list="config.scrolling_news" />
      </div>
    </div>
  </div>
</template>

<script>
import Weather from './components/weather.vue'
import LiveVideo from './components/video.vue'
import Sidebar from './components/sidebar.vue'
import ScrollingText from './components/scrolling-text.vue'

export default {
  name: 'App',
  components: { Weather, LiveVideo, Sidebar, ScrollingText },
  data() {
    return {
      loaded: false,
      config: {}
    }
  },
  mounted() {
    this.axios.get('config.json').then((response) => {
      this.config = response.data
      this.loaded = true
    })
  }
}
</script>

<style lang="scss" module>
.container {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  width: 100%;
  height: 100%;
}
.main {
  display: flex;
  flex: 1;
}
.content {
  display: flex;
  flex-direction: column;
  flex: 1;
}
.header {
  flex: 1;
}
.body {
  width: 82vw;
  aspect-ratio: 16/9;
}
.sidebar {
  width: 20vw;
  background: #4b92ec;
}
.footer {
  height: 100px;
  background: #2b4a89;
}
</style>
