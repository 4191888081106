<template>
  <div :class="$style.container">
    <div
      :class="$style.slide"
      v-for="(slide, index) in slides"
      :key="`slide-${index}`"
    >
      <template v-if="index === currentSlide">
        <div :class="$style.title">{{ slide.title }}</div>
        <div
          :class="$style[content.type]"
          v-for="(content, cindex) in slide.content"
          :key="`slide-${index}-content-${cindex}`"
        >
          {{ content.text }}
        </div>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Sidebar',
  props: {
    slides: {
      required: true
    },
    duration: {
      default: 10
    }
  },
  data() {
    return {
      currentSlide: 0
    }
  },
  mounted() {
    setInterval(() => {
      this.currentSlide++
      if (this.currentSlide === this.slides.length) {
        this.currentSlide = 0
      }
    }, 1000 * this.duration)
  }
}
</script>

<style lang="scss" module>
.container {
  padding: 50px 30px;
}

.slide {
  color: white;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.title {
  font-size: 38px;
  font-weight: bold;
  text-transform: uppercase;
}

.header {
  font-size: 34px;
  font-weight: bold;
}

.paragraph {
  line-height: 30px;
}
</style>
