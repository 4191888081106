<template>
  <a
    class="weatherwidget-io"
    :href="data.url"
    :data-label_1="data.label_1"
    :data-label_2="data.label_2"
    data-icons="Climacons"
    data-theme="sky"
  >
    TORONTO WEATHER
  </a>
</template>

<script>
export default {
  name: 'Weather',
  props: ['data'],
  mounted() {
    !(function (d, s, id) {
      var js,
        fjs = d.getElementsByTagName(s)[0]
      if (!d.getElementById(id)) {
        js = d.createElement(s)
        js.id = id
        js.src = 'https://weatherwidget.io/js/widget.min.js'
        fjs.parentNode.insertBefore(js, fjs)
      }
    })(document, 'script', 'weatherwidget-io-js')
  }
}
</script>
