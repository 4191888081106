<template>
  <youtube
    :video-id="videoId"
    :player-vars="playerVars"
    ref="youtube"
    width="100%"
    height="100%"
    @ready="playVideo"
    @playing="playing"
  ></youtube>
</template>

<script>
export default {
  name: 'Video',
  props: ['videoId'],
  data() {
    return {
      playerVars: {
        autoplay: 1,
        controls: 0
      },
      isPlaying: false
    }
  },
  computed: {
    player() {
      return this.$refs.youtube.player
    }
  },
  methods: {
    playVideo() {
      this.player.playVideo()
    },
    playing() {
      this.isPlaying = true
    },
    forceToPlay() {
      setTimeout(() => {
        if (!this.isPlaying) {
          this.playVideo()
          this.forceToPlay()
          console.log('Not Playing!')
        }
      }, 3000)
    }
  },
  mounted() {
    this.forceToPlay()
  }
}
</script>
